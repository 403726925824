<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Verwijdering account`">
        <LoadingIcon :isLoading="isLoading"/>
        <div class="px-2" v-if="!isLoading">
            <v-container class="pt-0">
                <v-row v-if="isValid" pt-0>
                    <v-col cols="12" class="py-0">
                    <p>Beste {{firstname}},</p>
                    We vinden het jammer om u te zien gaan, maar bevestigen bij deze dat uw accountgegevens succesvol uit ons systeem zijn verwijderd.
                    </v-col>
                </v-row>
                <v-row v-if="!isValid">
                    <v-col cols="12" class="py-0">
                        <div>
                            Er ging iets mis bij het verwijderen van uw account. Heeft u misschien twee keer geklikt? Als u hulp nodig heeft kunt u contact opnemen met
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    style="color:#76baff"
                                    target="_blank"
                                    href="mailto:service@roadpursuit.com"
                                    @click.stop
                                    v-on="on">
                                    service@roadpursuit.com
                                </a>
                                </template>
                                Opent uw mail app
                            </v-tooltip>
                            .
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import LoadingIcon from '@/components/common/LoadingIcon';
import TokenService from "@/services/token.service";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'ConfirmAccountDeletion',
    components: 
    {
        BannerTemplate,
        LoadingIcon
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        const deletionString = this.$route.params.deletionString;
        this.$store.dispatch('accountModule/confirmAccountDeletionRequest', deletionString)
            .then(data => {
                this.firstname = data.item1;
                this.isValid = data.item2;
                this.isLoading = false;

                if(this.isValid)
                {
                    // remove user cache
                    TokenService.removeUser();
                }
            })
            .catch(() => {
                this.isValid = false;
                this.isLoading = false;
            })
    },
    mixins: [MetaTagsMixin],
    metaInfo() {
        return {
        title: '| Verwijdering account',
        meta: [
            this.getKeywords(`Verwijdering account`),
            this.getMetaDescription(`Verwijdering account`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Verwijdering account`, `Verwijdering account`,  '', ''),
        ]
        };
    },
    data: () => ({
        bannerSource:  require('@/assets/album/gallery_reviews_cover.jpg'),
        firstname: '',
        isValid: false,
        isLoading: true
    }),
}
</script>
